import React from "react";
import Inquiry from "./components/form"
import {Container} from "react-bootstrap"

export default function Contact() {
    return(
    <div>
        <Container >
        <Inquiry/>
        </Container>
    </div>
    )
}
