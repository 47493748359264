import React from "react";
import About from "./components/aboutus";
import CustomCarousel from "./components/carousel"

export default function index() {
    return(
        <>
        <CustomCarousel/>
        <About/>
        </>
    )
    
}
