import React from "react";

export default function Service(){
    return(
            <div className="" style = {{marginTop: "5em"}}>
            <h1 className="text-center">Our Services</h1>
            <p className="text-center">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint harum porro voluptatem, sunt expedita facilis totam quo omnis quasi dolorem modi tempora assumenda accusantium laborum nostrum quos voluptatum quaerat recusandae.</p>
            </div>
            
    )
}